import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom'
import { UserStore } from '../stores/user'
import user = require('../user')
import { BlockScreenStore } from '../stores/block-screen'
import { AuthType } from '../../../common/define'

interface HomeProps extends Partial<RouteComponentProps> {
  userStore?: UserStore
  blockScreenStore?: BlockScreenStore
}

@(withRouter as any)
@inject('userStore')
@inject('blockScreenStore')
@observer
export class Home extends Component<HomeProps> {
  constructor(props: HomeProps) {
    super(props)
    console.log('Home()', props.userStore!.isLogon, window.location.href)
  }

  async login(authType: AuthType) {
    await this.props.blockScreenStore!.block()
    const succeed: boolean = await user.login(authType)
    await this.props.blockScreenStore!.release()

    if (succeed) {
      UIkit.notification("<span uk-icon='icon: check'></span> 로그인 성공!", { pos: 'bottom-center', status: 'primary' });
    }
  }

  render() {
    if (this.props.userStore!.isLogon) {
      return <Redirect to="/timeline/all"></Redirect>
    }

    return <div
      className="uk-flex uk-flex-column uk-flex-middle uk-height-viewport"
      style={{
        justifyContent: 'center',
        backgroundColor: '#f2f2f2'
      }}>

      <div className="uk-flex uk-flex-column uk-text-center uk-position-relative" style={{ width: '80%', justifyContent: 'center' }}>
        <div >
          <img src="/static/images/home04/title.jpg" style={{ maxHeight: '100px' }}></img>
        </div>
        <div >
          <img src="/static/images/home04/logo.jpg" style={{ maxHeight: '500px' }}></img>
        </div>
        <div style={{ width: '80%', position: 'relative', left: '10%' }}>
          <img src="/static/images/home04/subtitle.jpg" style={{ maxHeight: '100px' }}></img>
        </div>
      </div>

      <div className="uk-margin-large-top uk-text-center">
        <img className="uk-link"
          src="/static/images/facebook/facebook-icon-circle-logo.png"
          style={{ height: '50px' }}
          onClick={() => this.login(AuthType.Facebook)}></img>

        <img className="uk-margin-small-left uk-link"
          src="/static/images/kakaotalk/kakaotalk-icon-circle.png"
          style={{ height: '50px' }}
          onClick={() => this.login(AuthType.Kakaotalk)}></img>
        <div className="uk-text uk-margin-small-top">Login</div>
      </div>
    </div >
  }
}
