import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { UserStore } from '../stores/user'
import { TimelineType, PostInfo, UserId } from '../../../common/define'
import { PostStore } from '../stores'
import { Feed } from './feed'
import userHash = require('../user-hash')
import post = require('../post')
import user = require('../user')
import topic = require('../topic')
import { observable } from 'mobx'
import { BlockScreenStore } from '../stores/block-screen'

interface TimelineProps extends Partial<RouteComponentProps<{ hash: string }>> {
  userStore?: UserStore
  postStore?: PostStore
  blockScreenStore?: BlockScreenStore
  type: TimelineType
}

@(withRouter as any)
@inject('userStore')
@inject('postStore')
@inject('blockScreenStore')
@observer
export class Timeline extends Component<TimelineProps> {
  @observable isLoading: boolean = false

  constructor(props: TimelineProps) {
    super(props)
    console.log('Timeline()', props.userStore!.isLogon)

    this.init()
  }

  async updatePosts() {
    this.isLoading = true

    switch (this.props.type) {
      case TimelineType.All: await post.updateAll(); break
      case TimelineType.Mine: await post.updateUser(this.props.userStore!.userId); break
      case TimelineType.User: await post.updateUser(this.targetUserId); break
      case TimelineType.Subscribe: await post.updateSubscribe(); break
      default:
        console.error(`invalid-timeline-type-${this.props.type}`)
    }

    this.isLoading = false
  }

  async init() {
    console.log('timeline.init()')

    await topic.update()
    console.log('timeline.init() topic updated')

    post.init()
    await this.updatePosts()

    console.log('timeline.init() posts updated at first time.', { count: this.props.postStore!.count })
    if (0 < this.props.postStore!.count) {
      const observer: IntersectionObserver = new IntersectionObserver(async (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        const lastEntry = [...entries].pop();
        if (lastEntry && lastEntry.isIntersecting) {
          console.log('timeline.init() intersecting!')

          // @ts-ignore
          const lastPostId: string = lastEntry?.target.attributes['data-post-id'].value

          observer.unobserve(lastEntry.target)
          await this.updatePosts()

          const newLastEntry = document.querySelector('.post:last-child')
          // @ts-ignore
          const newLastPostId: string = newLastEntry?.attributes['data-post-id'].value

          if (lastPostId !== newLastPostId) {
            const element = document.querySelector('.post:last-child')
            if (element) {
              observer.observe(element)
            }
          }
        }
      }, { threshold: 0.5 })
      const element = document.querySelector('.post:last-child')
      if (element) {
        observer.observe(element)
      }
    }
  }

  async subscribe() {
    await this.props.blockScreenStore!.block()
    const succeed: boolean = await user.subscribe(this.targetUserId)
    await this.props.blockScreenStore!.release()

    if (succeed) {
      UIkit.notification("<span uk-icon='icon: check'></span> 구독 되었습니다!", { pos: 'bottom-center', status: 'primary' })
    }
  }

  async unsubscribe() {
    try {
      await UIkit.modal.confirm('이제 구독 안하시려구요?')
    } catch (err) {
      console.log('timeline.unsubscribe() cancel to unsubscribe.', { err })
      return
    }

    await this.props.blockScreenStore!.block()
    const succeed: boolean = await user.unsubscribe(this.targetUserId)
    console.log('timeline.unsubscribe() unsubscribe-reuslt.', { succeed })
    await this.props.blockScreenStore!.release()

    if (succeed) {
      UIkit.notification("<span uk-icon='icon: check'></span> 구독 취소되었습니다!", { pos: 'bottom-center', status: 'primary' })
    }
  }

  get isAllType(): boolean {
    return TimelineType.All === this.props.type
  }

  get isMineType(): boolean {
    return TimelineType.Mine === this.props.type
  }

  get isTargetUserType(): boolean {
    return TimelineType.User === this.props.type
  }

  get isSubscribeType(): boolean {
    return TimelineType.Subscribe === this.props.type
  }

  get targetUserHash(): string {
    return this.props.match!.params.hash
  }

  get targetUserId(): UserId {
    const decodedUserId: string = userHash.decodeUserHash(this.props.match!.params.hash)
    return UserId.create(decodedUserId)
  }

  get isTargetUserSubscribed(): boolean {
    return this.props.userStore!.subscribeUserIds.includes(this.targetUserId)
  }

  render() {
    return <div
      uk-height-viewport="offset-top:true;"
      className="uk-flex uk-flex-column uk-flex-top uk-height-viewport uk-container uk-container-xsmall uk-background-muted"
      style={{ alignItems: 'stretch' }}
    >
      {/* <Header /> */}
      <div className="uk-flex uk-flex-row uk-flex-center" >
        <ul className="uk-subnav uk-subnav-divider" >
          <li className={this.isAllType ? 'uk-active' : ''} ><Link to={'/timeline/all'} >모두 보기</Link></li>
          <li className={this.isMineType ? 'uk-active' : ''} ><Link to={'/timeline/mine'} >내글만</Link></li>
          <li className={this.isSubscribeType ? 'uk-active' : ''} ><Link to={'/timeline/subscribe'} >구독만</Link></li>
          {
            this.isTargetUserType &&
            <li className="uk-active">
              <a href="#" style={{ textTransform: 'none' }}>{this.targetUserHash} 작가만</a>
            </li>
          }
        </ul>
      </div>

      {
        this.isTargetUserType &&
        <div className="uk-text-center uk-margin-bottom">
          {
            this.isTargetUserSubscribed &&
            <button
              className="uk-button uk-button-danger uk-button-small uk-border-rounded"
              onClick={() => this.unsubscribe()}
            >구독 취소</button>
          }
          {
            !this.isTargetUserSubscribed &&
            <button
              className="uk-button uk-button-primary uk-button-small uk-border-rounded"
              onClick={() => this.subscribe()}
            >구독하기</button>
          }
        </div>
      }

      <div id="posts">
        {
          0 < this.props.postStore!.count &&
          this.props.postStore!.posts.map((post: PostInfo, key) =>
            <div data-post-id={post.id} className="post" key={key}>
              <Feed post={post} />
            </div>
          )
        }
        {
          this.props.postStore!.count <= 0 &&
          !this.isLoading &&
          <div className="uk-text-center uk-margin">글이 없어요!</div>
        }
      </div>

      {
        // this.props.postStore!.isLoading &&
        this.isLoading &&
        <div className="uk-text-center uk-margin-top uk-margin-bottom" >
          <div uk-spinner="true"></div>
        </div>
      }
      <br></br>
    </div >
  }
}
