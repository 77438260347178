import { TopicId, PostId, UserId, TopicInfo } from '../../common/define'
import { postStore, topicStore } from './stores'
import fetchApi = require('./fetch-api')
import moment = require('moment')

export async function update(): Promise<boolean> {
  console.log('topic.update()')

  const api: string = `topics?since=${topicStore.lastTopicUpdateTime}`
  const res: Response | undefined = await fetchApi.fetchApi(api)
  if (!res) {
    return false
  }

  const { succeed, topics } = await res.json()
  console.log('topics.update() succeed to paarse topics.', { succeed, topics })

  if (!succeed) {
    return false
  }

  const updateTime: number = moment.utc().unix()
  topics.forEach((topic: TopicInfo) => topicStore.update(topic, updateTime))

  return 0 < topics.length
}
