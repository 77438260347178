import { observable, action, computed } from 'mobx'
import { TopicInfo, TopicId } from '../../../common/define'

export class TopicStore {
  @observable topics: TopicInfo[]
  @observable lastTopicUpdateTime: number

  constructor() {
    const [topics, time] = this.load()
    this.topics = topics
    this.lastTopicUpdateTime = time
    console.log('TopicStore()', { topics, time })
  }

  private load(): [TopicInfo[], number] {
    const topics: TopicInfo[] = []

    const cachedTopics = window.localStorage.getItem('topics')
    if (cachedTopics) {
      const infos: TopicInfo[] = JSON.parse(cachedTopics)
      infos.forEach((topic: TopicInfo) => topics.push(topic))
    }
    const lastTopicUpdateTime: number = Number(window.localStorage.getItem('lastTopicUpdateTime') || '0')
    console.log('topicStore.load()', topics, lastTopicUpdateTime)

    return [topics, lastTopicUpdateTime]
  }

  private store(): void {
    window.localStorage.setItem('topics', JSON.stringify(this.topics))
    window.localStorage.setItem('lastTopicUpdateTime', `${this.lastTopicUpdateTime}`)
  }

  @action.bound
  public async update(topic: TopicInfo, updateTime: number): Promise<boolean> {
    console.log('topicStore.update()', { topic })

    const existingTopic = this.topics.find(({ id }) => id === topic.id)
    if (existingTopic) {
      Object.assign(existingTopic, topic)
    } else {
      this.topics.push(topic)
    }

    this.topics = this.topics.slice().sort((lhs: TopicInfo, rhs: TopicInfo) => rhs.id - lhs.id)
    this.lastTopicUpdateTime = updateTime
    this.store()

    return true
  }

  @computed
  public get firstTopicId(): TopicId {
    if (this.topics.length <= 0) {
      return TopicId.create(undefined)
    }
    return this.topics[0].id
  }
}
