import { Stage } from '../../../common/define'
import devConfig from './dev'
import testConfig from './test'
import prodConfig from './prod'

function importLocalConfig() {
  try {
    // do not add local-config file to repository.
    const localConfig = require('./local')
    return localConfig.default
  } catch (err) {
    console.warn('config.importLocalConfig() no-file', err)
  }
  return undefined
}
const localConfig = importLocalConfig()

const templateConfig = {
  facebookAppId: 'facebook app id (dev/test/prod)',
  facebookVersion: 'v6.0',
  apiHost: 'api server host here',
  facebookPageUrl: 'https://www.facebook.com/일상노트-108072477468750',
  facebookMessengerUrl: 'https://m.me/108910064146676',
  kakaotalkChannelUrl: 'http://pf.kakao.com/_BDxnXxb/chat',
  kakaotalkChannelPublicId: '_BDxnXxb', 
  writeHelpText: `글감은 매일 하나씩 업데이트됩니다.
  영감을 불어넣을 문구가 함께 제공됩니다.

  매일 글 쓰는 즐거움 챙기시길 바랍니다.
  문의 사항은 페이스북을 통해서 부탁드립니다. `,
  minTextLength: 10,
  jobValidationById: true,
}

const configObj = Object.assign({}, templateConfig)
export default configObj

export function init(stageForce?: Stage): void {
  console.log('config.init()', { stageForce, templateConfig, devConfig, testConfig, prodConfig, localConfig, env: process.env['stage'] })
  const stage: Stage = stageForce ? stageForce :
    process.env['stage'] ? Number(process.env['stage']) as Stage :
      Stage.Dev

  switch (stage) {
    case Stage.Prod: Object.assign(configObj, prodConfig); break
    case Stage.Test: Object.assign(configObj, testConfig); break
    case Stage.Dev: Object.assign(configObj, devConfig, localConfig); break
  }

  console.log('config.init() complete!', { configObj })
}

init()
