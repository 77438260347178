import { observable, action, computed } from 'mobx'
import config from '../config'
import { Token, Const, UserId, TopicId, PostId } from '../../../common/define'

export class UserStore {
  @observable token: Token
  @observable userId: UserId
  @observable subscribeUserIds: UserId[]

  constructor() {
    const [token, userId, subscribeUserIds] = this.load()
    this.token = token
    this.userId = userId
    this.subscribeUserIds = subscribeUserIds
    console.log('UserStore()', { token, userId, subscribeUserIds })
  }

  private load(): [Token, UserId, UserId[]] {
    const token: Token = window.localStorage.getItem('token') || ''
    const userId: UserId = window.localStorage.getItem('userId') || ''
    const subscribeUserIds: UserId[] = JSON.parse(window.localStorage.getItem('subscribeUserIds') || '[]')
    return [token, userId, subscribeUserIds]
  }

  private store(): void {
    window.localStorage.setItem('token', this.token)
    window.localStorage.setItem('userId', this.userId)
    window.localStorage.setItem('subscribeUserIds', JSON.stringify(this.subscribeUserIds))
  }

  @action.bound
  public clear(): void {
    this.token = ''
    this.userId = ''
    this.subscribeUserIds = []
    this.store()
  }

  @computed
  public get isLogon(): boolean {
    return '' !== this.token && '' !== this.userId
  }

  @action.bound
  public async login(token: Token, userId: UserId, subscribeUserIds: UserId[]): Promise<boolean> {
    this.token = token
    this.userId = userId
    this.subscribeUserIds = subscribeUserIds
    this.store()

    return true
  }

  @action.bound
  public logout(): void {
    this.clear()
  }

  @action.bound
  public subscribe(userId: UserId): boolean {
    const index = this.subscribeUserIds.findIndex(id => id === userId)
    if (0 <= index) {
      console.log('userStore.subscribe() already subscribed!', { userId })
      return false
    }
    this.subscribeUserIds.push(userId)
    this.store()
    return true
  }

  @action.bound
  public unsubscribe(userId: UserId): boolean {
    const index = this.subscribeUserIds.findIndex(id => id === userId)
    if (index < 0) {
      console.log('userStore.unsubscribe() no-info-found!', { userId })
      return false
    }
    this.subscribeUserIds.splice(index, 1)
    this.store()
    return true
  }
}

