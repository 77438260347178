import { observable, action, computed } from 'mobx'

export class BlockScreenStore {
  @observable private flag: boolean

  constructor() {
    this.flag = false
    console.log('BlockScreenStore()')
  }

  @action.bound
  public async block() {
    this.flag = true
  }

  @action.bound
  public async release() {
    await new Promise(resolve => setTimeout(resolve, 100))
    this.flag = false
  }

  @computed
  public get isBlocking(): boolean {
    return this.flag
  }
}
