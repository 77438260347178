import config from './config'

export async function initSdk() {
  console.log('facebook.initSdk()')

  return new Promise(resolve => {
    const appId: string = '537148790253079'
    const version: string = 'v6.0'

    // @ts-ignore
    window.fbAsyncInit = () => {
      console.log('initFacebook() - fbAsncInit()', window.FB)
      FB.init({ appId: config.facebookAppId, cookie: true, xfbml: true, version: config.facebookVersion })
      FB.AppEvents.logPageView()

      resolve()
    }

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      // @ts-ignore
      js.src = `https://connect.facebook.net/ko_KR/sdk.js`;
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  })
}

export async function getLoginStatus(): Promise<fb.StatusResponse> {
  console.log('facebook.getLoginStatus()')

  return new Promise(resolve => FB.getLoginStatus(resolve))
}

export async function login(): Promise<fb.StatusResponse> {
  return new Promise(resolve => FB.login(resolve))
}

export function contact(): void {
  window.location.replace(config.facebookMessengerUrl)
}