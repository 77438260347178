import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { TopicInfo, TopicId, WriteType, PostId } from '../../../common/define'
import { TopicStore } from '../stores'
import config from '../config'
import post = require('../post')
import topic = require('../topic')
import { BlockScreenStore } from '../stores/block-screen'
import { observable } from 'mobx'

interface WriteProps extends Partial<RouteComponentProps<{ postId: string, topicId: string }>> {
  topicStore?: TopicStore
  blockScreenStore?: BlockScreenStore
  type: WriteType
}

function _getTextKey(topicId: TopicId, isEditing: boolean = false): string {
  if (isEditing) {
    return `editing#${topicId}`
  }
  return `writing#${topicId}`
}

function loadText(topicId: TopicId, isEditing: boolean = false): string {
  const textKey = _getTextKey(topicId, isEditing);
  return window.localStorage.getItem(textKey) || ''
}

function saveText(topicId: TopicId, text: string, isEditing: boolean = false): void {
  const textKey = _getTextKey(topicId, isEditing);
  window.localStorage.setItem(textKey, text)
}

function clearText(topicId: TopicId, isEditing: boolean = false): void {
  const textKey = _getTextKey(topicId, isEditing);
  window.localStorage.removeItem(textKey)
}

@(withRouter as any)
@inject('topicStore')
@inject('blockScreenStore')
@observer
export class Write extends Component<WriteProps> {
  @observable topicId: TopicId
  @observable text: string

  constructor(props: WriteProps) {
    super(props)
    console.log('Write()', { type: props.type })

    this.topicId = this.isEditing ? TopicId.create(props.match!.params.topicId) : props.topicStore!.firstTopicId
    this.text = this.isEditing ? decodeURI(props.location!.search.match(/text=(.+)/)![1]) : loadText(this.topicId)

    this.init()
  }

  async init(): Promise<void> {
    console.log('write.init()')
    await topic.update()
  }

  get isEditing(): boolean {
    return WriteType.Edit === this.props.type
  }

  get editPostId(): PostId {
    return PostId.create(this.props.match!.params.postId)
  }

  get topic(): TopicInfo | undefined {
    return this.props.topicStore!.topics.find(({ id }) => id === this.topicId)
  }

  selectTopic(topicId: TopicId) {
    if (this.isEditing) {
      console.error('write.selectTopic() not available on edit-mode.')
      return
    }
    this.topicId = topicId
    this.text = loadText(topicId)
  }

  writing(text: string) {
    console.log('write.writing()', text)
    this.text = text
    saveText(this.topicId, text, this.isEditing)
  }

  async submit() {
    if (!TopicId.isValid(this.topicId)) {
      console.error('write.submit() invalid-topic-id', { topicId: this.topicId })
      return
    }

    if (this.isEditing && !PostId.isValid(this.editPostId)) {
      console.error('write.submit() invalid-post-id', { postId: this.editPostId })
      return
    }

    if (this.text.length < config.minTextLength) {
      console.error('write.submit() not-enough-characters-in-text')
      return UIkit.modal.alert(`최소 ${config.minTextLength} 글자는 써주세요!`)
    }

    await this.props.blockScreenStore!.block()

    const succeed = this.isEditing ?
      await post.edit(this.editPostId, this.topicId, this.text) :
      await post.write(this.topicId, this.text)

    await this.props.blockScreenStore!.release()

    if (succeed) {
      clearText(this.topicId, this.isEditing)
      UIkit.notification(`<span uk-icon='icon: check'></span> ${this.isEditing ? '고치기' : '글쓰기'} 성공!`, { pos: 'bottom-center', status: 'primary' })
      this.props.history!.push('/timeline/mine')
    }
  }

  render() {
    return <div
      uk-height-viewport="offset-top:true;"
      className="uk-flex uk-flex-column uk-flex-top uk-height-viewport uk-container uk-container-xsmall uk-background-muted"
      style={{ alignItems: 'stretch' }}
    >
      {/* <Header /> */}
      <div className="uk-form-label">
        <span uk-icon="icon: pencil" />
        <span className="uk-margin-small-left" style={{ verticalAlign: 'middle' }}>글감</span>
      </div>

      <select
        className="uk-select uk-margin-small-top"
        onChange={e => this.selectTopic(TopicId.create(e.target.value))}
        disabled={this.isEditing}
        defaultValue={this.topicId}
      >
        {
          this.props.topicStore!.topics.map((topic: TopicInfo) =>
            <option key={topic.id} value={topic.id} >
              {topic.title} #{topic.id.toString().padStart(3, '0')}
            </option>
          )
        }
      </select>

      {
        this.topic &&
        <div className="uk-margin-top uk-margin-left uk-margin-right uk-text-small" >
          {
            this.topic!.desc.split('\n').map((text, key) => <div key={key} style={{ lineHeight: 1 }}>{text}</div>)
          }
        </div>
      }

      <div className="uk-form-label uk-margin-top">
        <span uk-icon="icon: commenting" />
        <span className="uk-margin-small-left" style={{ verticalAlign: 'middle' }}>작성</span>
      </div>
      <textarea
        className="uk-textarea uk-margin-small-top"
        style={{ flexGrow: 1, minHeight: '5rem' }}
        onChange={e => this.writing(e.target.value)}
        value={this.text}
      // defaultValue={this.text}
      />

      <div
        className="uk-form-label uk-margin-top uk-margin-bottom uk-width-expand uk-flex uk-flex-right"
        style={{ flexGrow: 0 }}
      >
        <button
          className="uk-button uk-button-secondary uk-button-small uk-inline"
          onClick={() => this.submit()}
        // disabled={this.props.userStore!.isLoading}
        >
          {this.isEditing ? '수정 완료' : '작성 완료'}
        </button>
      </div>

      <div className="uk-width-1-1 uk-text uk-text-center uk-text-small">
        {
          config.writeHelpText.split('\n').map((text, key) => <div key={key} style={{ lineHeight: 1 }}>{text ? text : <br></br>}</div>)
        }
      </div>
      <br></br>
    </div >
  }
}
