import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Header, Home, Timeline, Write, BlockScreen } from './components'
import { UserStore, TopicStore } from './stores'
import facebook = require('./facebook')
import kakaotalk = require('./kakaotalk')
import { TimelineType, WriteType } from '../../common/define'

interface AppProps {
  userStore?: UserStore
  topicStore?: TopicStore
}

@inject('userStore')
@inject('topicStore')
@observer
class App extends Component<AppProps> {
  constructor(props: AppProps) {
    super(props)
    console.log('App()', props.userStore!.isLogon, props.userStore!.token, props.topicStore!.topics)

    this.init()
  }

  async init() {
    console.log('app.init()')
    await facebook.initSdk()
    console.log('app.init() facebook init completed')
    const fbStatus: fb.StatusResponse = await facebook.getLoginStatus()
    console.log('app.init() facebook status', fbStatus)
    await kakaotalk.init()
    console.log('app.init() init kakaltalk.')
    const accessToken: string = await kakaotalk.getAccessToken()
    console.log('app.init() kakaotalk prev-access-token.', { accessToken })
  }

  render() {
    console.log('App.render()')

    return (
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact={true} path="/home" component={Home} />
          <Route exact={true} path="/timeline/all" component={() => <Timeline type={TimelineType.All} />} />
          <Route exact={true} path="/timeline/mine" component={() => <Timeline type={TimelineType.Mine} />} />
          <Route exact={true} path="/timeline/subscribe" component={() => <Timeline type={TimelineType.Subscribe} />} />
          <Route exact={true} path="/timeline/user/:hash" component={() => <Timeline type={TimelineType.User} />} />
          <Route exact={true} path="/write" component={() => <Write type={WriteType.Write} />} />
          <Route exact={true} path="/edit/:postId/:topicId" component={() => <Write type={WriteType.Edit} />} />
          {/* Not Found */}
          <Route component={() => <Redirect to="/home" />} />
        </Switch>
        <BlockScreen />
      </BrowserRouter>
    )
  }
}

export default App
