import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { RouteComponentProps, withRouter, Redirect, Link } from 'react-router-dom'
import { UserStore } from '../stores/user'
import { AuthType } from '../../../common/define'
import facebook = require('../facebook')
import kakaotalk = require('../kakaotalk')

interface HeaderProps extends Partial<RouteComponentProps> {
  userStore?: UserStore
}

const enum NavType { None, Timeline, Write }

@(withRouter as any)
@inject('userStore')
@observer
export class Header extends Component<HeaderProps> {
  constructor(props: HeaderProps) {
    super(props)
    console.log('Header()')
  }

  private getCurrentNavType(): NavType {
    if (!this.props.location) {
      return NavType.None
    }

    const { pathname } = this.props.location
    if (0 <= pathname.search('/write')) {
      return NavType.Write
    } else if (0 <= pathname.search('/timeline')) {
      return NavType.Timeline
    }

    return NavType.None
  }

  async handleLogout() {
    UIkit.modal.confirm('정말 로그아웃?')
      .then(() => this.props.userStore!.logout())
      .catch((err: Error) => console.log('header.handleLogout() logout canceled.', { err }))
  }

  async handleContact(authType: AuthType) {
    switch (authType) {
      case AuthType.Facebook: {
        UIkit.modal.confirm('페이스북 메신저로 문의합니다')
          .then(facebook.contact)
          .catch((err: Error) => console.log('header.handleContact() failed.', { err }))
        break
      }
      case AuthType.Kakaotalk: {
        UIkit.modal.confirm('카카오톡으로 문의합니다')
          .then(kakaotalk.contact)
          .catch((err: Error) => console.log('header.handleContact() failed.', { err }))
        break
      }
      default: {
        console.error('header.handleContact() invalid-auth-type.', { authType })
        break
      }
    }
  }

  render() {
    return this.props.userStore!.isLogon ?
      <div id="header"
        className="uk-flex uk-flex-column uk-flex-top uk-height-viewport uk-container uk-container-xsmall uk-background-muted"
        style={{ alignItems: 'stretch', minHeight: '0vh' }}
      >
        <nav className="uk-navbar-container" uk-navbar="true">
          <div className="uk-navbar-left">
            <button className="uk-navbar-toggle" uk-navbar-toggle-icon="true" uk-toggle="target: #sidebar" />
          </div>
          <div className="uk-navbar-center">
            <Link to="/" className="uk-navbar-item uk-logo" style={{ fontFamily: 'sdmisaeng' }}>일상 노트</Link>
          </div>
          {this.props.userStore!.isLogon &&
            <div className="uk-navbar-right">
              <div className="uk-navbar-item">
                <button className="uk-icon-button" uk-icon="user" onClick={() => this.handleLogout()} >
                </button>
              </div>
            </div>
          }
        </nav>
        <div id="sidebar" uk-offcanvas="mode: reveal; overlay: true">
          <div className="uk-offcanvas-bar uk-flex uk-flex-column">
            <h3 className="uk-logo" style={{ fontFamily: 'sdmisaeng' }}>일상 노트</h3>
            <ul className="uk-nav uk-nav-default">
              <li className={NavType.Write === this.getCurrentNavType() ? 'uk-active' : ''}>
                <Link to="/write" onClick={() => UIkit.offcanvas(document.querySelector('#sidebar')!).hide()}>
                  <span uk-icon="icon: pencil" />
                  <span className="uk-margin-small-left" style={{ verticalAlign: 'middle' }}> 글쓰기 </span>
                </Link>
              </li>
              <li className={NavType.Timeline === this.getCurrentNavType() ? 'uk-active' : ''}>
                <Link to="/timeline/all" onClick={() => UIkit.offcanvas(document.querySelector('#sidebar')!).hide()}>
                  <span uk-icon="icon: file-text" />
                  <span className="uk-margin-small-left" style={{ verticalAlign: 'middle' }}> 타임라인 </span>
                </Link>
              </li>
            </ul>
            <div className="uk-flex uk-flex-column-reverse" style={{ flexGrow: 1 }}>
              <div className="uk-flex uk-flex-column uk-margin-bottom">
                <hr></hr>
                <div className="uk-flex uk-flex-row uk-flex-center">
                  <img className="uk-link"
                    src="/static/images/facebook/facebook-icon-circle-logo.png"
                    style={{ height: '50px' }}
                    onClick={() => this.handleContact(AuthType.Facebook)}></img>

                  <img className="uk-margin-small-left uk-link"
                    src="/static/images/kakaotalk/kakaotalk-icon-circle.png"
                    style={{ height: '50px' }}
                    onClick={() => this.handleContact(AuthType.Kakaotalk)}></img>
                </div>
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div >
      : <Redirect to="/home"></Redirect>
  }
}
