export type TopicId = number
export namespace TopicId {
  const invalidId: TopicId = 0
  export function create(id?: number | string): TopicId {
    if (!id) {
      return invalidId
    }
    return Number(id)
  }
  export function isValid(id: TopicId): boolean {
    return id !== invalidId
  }
}
export interface TopicInfo {
  id: TopicId
  title: string
  desc: string
  publishTime: number
}

export const enum Stage {
  Dev, Test, Prod,
}

export const enum AuthType {
  None, Facebook, Kakaotalk,
}

export type Token = string

export interface TokenObj {
  authType: AuthType
  authVal: string
  expireTime: number
}

export const enum TimelineType {
  All, Mine, User, Subscribe,
}

export const enum WriteType {
  Write, Edit,
}

export type PostId = string
export namespace PostId {
  const invalidId: PostId = ''
  export function create(id?: number | string): PostId {
    if (!id) {
      return invalidId
    }
    return `${id}`
  }
  export function isValid(id: PostId): boolean {
    return id !== invalidId
  }
}

export type UserId = string
export namespace UserId {
  const invalidId: UserId = ''
  export function create(id?: number | string): UserId {
    if (!id) {
      return invalidId
    }
    return `${id}`
  }
  export function isValid(id: UserId): boolean {
    return id !== invalidId
  }
}

export interface SubscribeInfo {
  fromUserId: UserId
  toUserId: UserId
  subscribeTime: number
}

export interface PostInfo {
  id: PostId
  userId: UserId
  topicId: TopicId
  text: string
  createTime: number
  updateTime: number
}

export namespace Const {
  export const POSTS_PER_PAGE: number = 3
  export const HTTP_ERROR_CODE_INVALID_TOKEN: number = 403
}
