import { UserId, SubscribeInfo, AuthType } from '../../common/define'
import facebook = require('./facebook')
import kakaotalk = require('./kakaotalk')
import { userStore } from './stores'
import fetchApi = require('./fetch-api')

export async function login(authType: AuthType): Promise<boolean> {
  console.log('user.login()', { authType })

  let accessToken: string
  switch (authType) {
    case AuthType.Facebook: {
      const fbRes = await facebook.login()
      console.log('user.login() succeed to login on facebook.', { fbRes })

      if (fbRes.status !== 'connected') {
        console.log('user.login() failed to login on fb-app.', { fbRes })
        return false
      }

      accessToken = fbRes.authResponse.accessToken
      break
    }
    case AuthType.Kakaotalk: {
      const result: boolean = await kakaotalk.login()
      if (!result) {
        console.log('user.login() failed to login on kakaotalk.', { result })
        return false
      }

      accessToken = kakaotalk.getAccessToken()
      break
    }
    default: {
      console.error('user.login() invalid auth type.', { authType })
      return false
    }
  }

  const res: Response | undefined = await fetchApi.fetchApi(`users/login/${authType}/${accessToken}`, { method: 'post' })
  console.log('user.login() succeed to fetch res.', { res })

  if (!res) {
    return false
  }

  const { token, userId, subscribes: subscribeInfos } = await res.json()
  console.log('user.login() succeed to parse fetch-res.', { token, userId, subscribeInfos })

  if (!token || !userId) {
    console.log('user.login() failed to login on dailynote.', { token, userId })
    return false
  }

  const subscribeUserIds: UserId[] = subscribeInfos.map((info: SubscribeInfo) => info.toUserId)
  return userStore.login(token, userId, subscribeUserIds)
}

export async function subscribe(userId: UserId): Promise<boolean> {
  console.log('user.subscribe()', { userId })

  const api: string = `users/subscribe/${userId}`
  const res: Response | undefined = await fetchApi.fetchApiWithToken(api, { method: 'post' })
  if (!res) {
    return false
  }

  const { succeed } = await res.json()
  console.log('user.subscribe() succeed to respond.', { succeed })

  if (!succeed) {
    return false
  }

  return userStore.subscribe(userId)
}

export async function unsubscribe(userId: UserId): Promise<boolean> {
  console.log('user.unsubscribe()', { userId })

  const api: string = `users/subscribe/${userId}`
  const res: Response | undefined = await fetchApi.fetchApiWithToken(api, { method: 'delete' })
  if (!res) {
    return false
  }

  const { succeed } = await res.json()
  console.log('user.unsubscribe() succeed to respond.', { succeed })

  if (!succeed) {
    return false
  }

  return userStore.unsubscribe(userId)
}