import moment from 'moment'
import React, { Component, RefObject } from 'react'
import { observer, inject } from 'mobx-react'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { PostInfo, TopicInfo } from '../../../common/define'
import { TopicStore, UserStore, PostStore } from '../stores'
import userHash = require('../user-hash')
import user = require('../user')
import post = require('../post')
import { BlockScreenStore } from '../stores/block-screen'

interface FeedProps extends Partial<RouteComponentProps> {
  topicStore?: TopicStore
  userStore?: UserStore
  postStore?: PostStore
  blockScreenStore?: BlockScreenStore
  post: PostInfo
  // mine: boolean
}

interface FeedState {
}

@(withRouter as any)
@inject('userStore')
@inject('postStore')
@inject('topicStore')
@inject('blockScreenStore')
@observer
export class Feed extends Component<FeedProps, FeedState> {
  private dropdownRef: RefObject<HTMLDivElement>
  constructor(props: FeedProps) {
    super(props)
    this.dropdownRef = React.createRef()
    console.log('Feed()', { props })
  }

  get topic(): TopicInfo | undefined {
    return this.props.topicStore?.topics.find(({ id }) => id === this.props.post.topicId)!
  }

  async edit() {
    console.log('feed.edit()')

    UIkit.dropdown(this.dropdownRef.current).hide();
    UIkit.modal.confirm('고치러 가시나요?')
      .then(() => this.props.history!.push(`/edit/${this.props.post.id}/${this.props.post.topicId}?text=${encodeURI(this.props.post.text)}`))
      .catch((err: Error) => console.log('feed.edit() cancel to edit.', { err }))
  }

  async delete() {
    console.log('feed.delete()')

    await UIkit.dropdown(this.dropdownRef.current).hide();

    try {
      await UIkit.modal.confirm('정말 삭제하나요? 삭제는 되돌릴 수 없어요.')
    } catch (err) {
      console.log('feed.deiete() cancel to delete.', { err })
      return
    }

    await this.props.blockScreenStore!.block()
    await post.remove(this.props.post.id)
    await this.props.blockScreenStore!.release()
  }

  async subscribe() {
    console.log('feed.subscribe()')

    await UIkit.dropdown(this.dropdownRef.current).hide();

    await this.props.blockScreenStore!.block()
    const succeed: boolean = await user.subscribe(this.props.post.userId)
    await this.props.blockScreenStore!.release()

    if (succeed) {
      UIkit.notification("<span uk-icon='icon: check'></span> 구독 되었습니다!", { pos: 'bottom-center', status: 'primary' })
    }
  }

  async unsubscribe() {
    console.log('feed.unsubscribe()')

    await UIkit.dropdown(this.dropdownRef.current).hide();

    try {
      await UIkit.modal.confirm('이제 구독 안하시려구요?')
    } catch (err) {
      console.log('timeline.unsubscribe() cancel to unsubscribe.', { err })
      return
    }

    await this.props.blockScreenStore!.block()
    const succeed: boolean = await user.unsubscribe(this.props.post.userId)
    await this.props.blockScreenStore!.release()

    if (succeed) {
      UIkit.notification("<span uk-icon='icon: check'></span> 구독 취소되었습니다!", { pos: 'bottom-center', status: 'primary' })
    }
  }

  get isMine(): boolean {
    return this.props.userStore!.userId === this.props.post.userId
  }

  get isSubscribing(): boolean {
    const index = this.props.userStore!.subscribeUserIds.findIndex(id => id === this.props.post.userId)
    return 0 <= index
  }

  render() {
    return <div className="uk-card uk-card-body uk-margin-small-bottom uk-position-relative" style={{ backgroundColor: '#fff' }} >
      <button className="uk-position-top-right uk-margin-small-top uk-margin-small-right" uk-icon="icon: more" type="button"></button>
      <div ref={this.dropdownRef} className="uk-padding-small" uk-dropdown="mode: click; delay-hide: 0;" >
        <ul className="uk-nav uk-dropdown-nav" >
          {
            this.isMine &&
            <li className="uk-active">
              <a href="#" onClick={() => this.edit()}>
                <span uk-icon="icon: pencil" />
                <span className="uk-margin-small-left" style={{ verticalAlign: 'middle' }}> 수정 </span>
              </a>
            </li>
          }
          {
            this.isMine &&
            <li className="uk-active">
              <a href="#" onClick={() => this.delete()}>
                <span uk-icon="icon: trash" />
                <span className="uk-margin-small-left" style={{ verticalAlign: 'middle' }}> 삭제 </span>
              </a>
            </li>
          }
          {
            !this.isMine &&
            <li className="uk-active">
              <a href={`/timeline/user/${userHash.encodeUserId(this.props.post.userId)}`}>
                <span uk-icon="icon: user" />
                <span className="uk-margin-small-left" style={{ verticalAlign: 'middle' }}> 이 작가의 글만 보기 </span>
              </a>
            </li>
          }
          {
            !this.isMine && this.isSubscribing &&
            <li className="uk-active">
              <a href="#" onClick={() => this.unsubscribe()}>
                <span uk-icon="icon: bookmark" />
                <span className="uk-margin-small-left" style={{ verticalAlign: 'middle' }}> 구독 취소 </span>
              </a>
            </li>
          }
          {
            !this.isMine && !this.isSubscribing &&
            <li className="uk-active">
              <a href="#" onClick={() => this.subscribe()}>
                <span uk-icon="icon: bookmark" />
                <span className="uk-margin-small-left" style={{ verticalAlign: 'middle' }}> 구독 </span>
              </a>
            </li>
          }
        </ul>
      </div>

      {
        this.isMine &&
        <span className="uk-badge" style={{ backgroundColor: 'gray' }}>내글</span>
      }
      {
        this.isSubscribing &&
        <span className="uk-badge" style={{ backgroundColor: 'green' }}>구독중</span>
      }

      <div className="uk-card-title uk-text-center uk-text-large uk-position-relative">
        {this.topic?.title}
      </div>
      <div className="uk-card-body uk-padding-remove-horizontal">
        {this.props.post.text.split('\n').map((text, key) => <div key={key}>{text ? text : <br></br>}</div>)}
      </div>
      <div className="uk-text-right uk-text-small" style={{ color: 'gray' }}>
        {userHash.encodeUserId(this.props.post.userId)}
      </div>
      <div className="uk-text-right uk-text-small" style={{ color: 'gray' }}>
        {moment.unix(this.props.post.createTime).format('L LT')}
      </div>
    </div>
  }
}
