import { Const } from '../../common/define'
import config from './config'
import { userStore } from './stores'

let globalAsyncJobNonce;

export async function fetchApi(api: string, init?: RequestInit): Promise<Response | undefined> {
  console.log('fetchApi.fetch()', { api, init })
  
  const localNonce = globalAsyncJobNonce = new Object();

  const res: Response = await fetch(`${config.apiHost}/${api}`, init)
  console.log('fetchApi.fetch() succeed to fetch.', { res })

  if (localNonce !== globalAsyncJobNonce) {
    console.log('fetchApi.fetch() interruped')
    // return undefined
  }

  return res
}

export async function fetchApiWithToken(api: string, init?: RequestInit): Promise<Response | undefined> {
  console.log('fetchApi.fetchApiWithToken()', { api, init })
  
  const localNonce = globalAsyncJobNonce = new Object();

  const delimiter: string = api.search(/\?/) < 0 ? '?' : '&'
  console.log('fetchApi.fetchApiWithToken() determine delimiter.', { delimiter })

  const res = await fetchApi(`${api}${delimiter}token=${userStore.token}`, init)
  console.log('fetchApi.fetchApiWithToken() succeed to fetch.', { res })

  if (localNonce !== globalAsyncJobNonce) {
    console.log('fetchApi.fetchApiWithToken() interruped')
    // return undefined
  }

  if (!res) {
    return undefined
  }

  if (Const.HTTP_ERROR_CODE_INVALID_TOKEN === res.status) {
    console.log('fetchApi.fetchApiWithToken() forbidden because of token.')
    userStore.clear()
    return undefined
  }

  return res
}