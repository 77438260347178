import { TopicId, PostId, UserId } from '../../common/define'
import { postStore } from './stores'
import fetchApi = require('./fetch-api')

let lastJobId: number = 0
async function genJobId(): Promise<number> {
  const now: number = Date.now()
  if (now !== lastJobId) {
    return lastJobId = now
  }

  await new Promise(resolve => setTimeout(resolve, 10))
  return genJobId()
}

const validJobIds: Set<number> = new Set()

export function init(): void {
  console.log('post.init()')
  validJobIds.clear()
  postStore.init()
}

export async function updateAll(): Promise<void> {
  console.log('post.updateAll()')

  const jobId: number = await genJobId()
  validJobIds.add(jobId)

  const api: string = `posts?lastPostId=${postStore.lastPostId}`
  const res: Response | undefined = await fetchApi.fetchApiWithToken(api)
  if (!res) {
    return
  }

  const { succeed, posts } = await res.json()
  console.log('post.updateAll() succeed to parse response.', { succeed, posts })

  if (!validJobIds.has(jobId)) {
    console.warn('post.updateAll() invalid-job-id', { validJobIds, jobId })
    return
  }
  validJobIds.delete(jobId)

  if (!succeed) {
    return
  }

  posts.forEach(postStore.add)
}

export async function updateUser(userId: UserId): Promise<void> {
  console.log('post.updateUser()', { userId })

  const jobId: number = await genJobId()
  validJobIds.add(jobId)

  const api: string = `posts/users/${userId}?lastPostId=${postStore.lastPostId}`
  const res: Response | undefined = await fetchApi.fetchApiWithToken(api)
  if (!res) {
    return
  }

  const { succeed, posts } = await res.json()
  console.log('post.updateUser() succeed to parse response.', { succeed, posts })

  if (!validJobIds.has(jobId)) {
    console.warn('post.updateUser() invalid-job-id', { validJobIds, jobId })
    return
  }
  validJobIds.delete(jobId)

  if (!succeed) {
    return
  }

  posts.forEach(postStore.add)
}

export async function updateSubscribe(): Promise<void> {
  console.log('post.updateSubscribe()')

  const jobId: number = await genJobId()
  validJobIds.add(jobId)

  const api: string = `posts/subscribed?lastPostId=${postStore.lastPostId}`
  const res: Response | undefined = await fetchApi.fetchApiWithToken(api)
  if (!res) {
    return
  }

  const { succeed, posts } = await res.json()
  console.log('post.updateSubscribe() succeed to parse response.', { succeed, posts })

  if (!validJobIds.has(jobId)) {
    console.warn('post.updateUnsubscribe() invalid-job-id', { validJobIds, jobId })
    return
  }
  validJobIds.delete(jobId)

  if (!succeed) {
    return
  }

  posts.forEach(postStore.add)
}

export async function write(topicId: TopicId, text: string): Promise<boolean> {
  console.log('post.write()', { topicId, text })

  const api: string = `users/write/${topicId}`
  const res: Response | undefined = await fetchApi.fetchApiWithToken(api, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text })
  })
  if (!res) {
    return false
  }
  const { succeed, postId } = await res.json()
  console.log('users.write() succeed to parse response.', { succeed, postId })

  return true
}

export async function edit(postId: PostId, topicId: TopicId, text: string): Promise<boolean> {
  console.log('post.edit()', { postId, topicId, text })

  const api: string = `users/edit/${postId}/${topicId}`
  const res: Response | undefined = await fetchApi.fetchApiWithToken(api, {
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text })
  })
  if (!res) {
    return false
  }
  const { succeed, postId: postIdResult } = await res.json()
  console.log('post.edit() succeed to parse response.', { succeed, postIdResult })

  return true
}

export async function remove(postId: PostId): Promise<boolean> {
  console.log('post.remove()', { postId })

  const api: string = `posts/${postId}`
  const res: Response | undefined = await fetchApi.fetchApiWithToken(api, { method: 'delete' })
  if (!res) {
    return false
  }

  const { succeed, postId: postIdResult } = await res.json()
  console.log('post.remove() succeed to parse response.', { succeed, postIdResult })

  if (!succeed) {
    return false
  }

  return postStore.remove(postId)
}
