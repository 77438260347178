export * from './user'
export * from './topic'
export * from './post'

import { UserStore } from './user'
import { TopicStore } from './topic'
import { PostStore } from './post'
import { BlockScreenStore } from './block-screen'

export const userStore: UserStore = new UserStore()
export const topicStore: TopicStore = new TopicStore()
export const postStore: PostStore = new PostStore()
export const blockScreenStore: BlockScreenStore = new BlockScreenStore()
