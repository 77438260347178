import Hashids from 'hashids'
import { UserId } from '../../common/define'

const hashids = new Hashids('dailynote', 6)

export function encodeUserId(id: UserId): string {
  return hashids.encode(id)
}

export function decodeUserHash(hash: string): UserId {
  console.log('userHash.decodeUserHash()', { hash })
  // @ts-ignore
  return UserId.create(hashids.decode(hash)[0])

} 
