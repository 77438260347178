import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'

import App from './app'
import stores = require('./stores')

declare global {
  const UIkit: any
}

ReactDOM.render(
  <Provider
    userStore={stores.userStore}
    topicStore={stores.topicStore}
    postStore={stores.postStore}
    blockScreenStore={stores.blockScreenStore}
  ><App /></Provider>,
  document.getElementById('app')
)
