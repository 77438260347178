import { observable, action, computed } from 'mobx'
import { PostInfo, PostId } from '../../../common/define'

export class PostStore {
  @observable posts: PostInfo[]

  constructor() {
    this.posts = []
    console.log('PostStore()', { posts: this.posts })
  }

  @action.bound
  public init() {
    this.posts = []
  }

  @computed
  public get count(): number {
    return this.posts.length
  }

  @computed
  public get lastPostId(): PostId {
    if (this.posts.length <= 0) {
      return PostId.create()
    }
    return this.posts[this.posts.length - 1].id
  }

  @action.bound
  public add(post: PostInfo): boolean {
    this.posts.push(post)
    return true
  }

  @action.bound
  public remove(postId: PostId): boolean {
    const index = this.posts.findIndex((post: PostInfo) => post.id === postId)
    if (index < 0) {
      return false
    }

    this.posts.splice(index, 1)
    return true
  }
}
