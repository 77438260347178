import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { BlockScreenStore } from '../stores/block-screen'

interface BlockScreenProps extends Partial<RouteComponentProps> {
  blockScreenStore?: BlockScreenStore
}

@(withRouter as any)
@inject('blockScreenStore')
@observer
export class BlockScreen extends Component<BlockScreenProps> {
  constructor(props: BlockScreenProps) {
    super(props)
  }

  render() {
    return <div className="uk-modal uk-flex uk-flex-middle" style={{
      display: this.props.blockScreenStore!.isBlocking ? 'flex' : 'none',
      opacity: this.props.blockScreenStore!.isBlocking ? 1 : 0,
    }}>
      <div className="uk-text-center uk-margin-top uk-margin-bottom" style={{ flexGrow: 1 }}>
        <div uk-spinner="true"></div>
      </div>
    </div>
  }
}
